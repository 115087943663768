const DEFAULT_WAIT = 500;

/**
 * @param {Function} fn - Function to be debounced.
 * @param {Number} wait - Time in millis to wait before calling the function.
 * @return {Function} - Debounced function.
 */
export function debounce(fn, wait = DEFAULT_WAIT) {
	let timer;

	// eslint-disable-next-line fp/no-rest-parameters
	return function debouncedFunction(...args) {
		return new Promise(resolve => {
			if (timer) {
				clearTimeout(timer);
			}

			const context = this;

			timer = setTimeout(() => {
				resolve(fn.apply(context, args));
			}, wait);
		});
	};
}
